import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

// components
import { MainNav } from 'src/components/navigation';
import { ConsentManager } from 'src/containers/ConsentManager';
import Footer from 'src/components/footer';
import { Banner, UnidentifiedTraffic } from 'src/components/Feedback';
import { Unless } from 'src/components/helpers';
import { MetaTags } from 'src/components/MetaTags';
import { MainMenu } from 'src/sections/Navigation';

// contexts
import { AnalyticsProvider, LocaleContext } from 'src/contexts';

// helpers
import { getCurrentPage } from 'src/lib/utils';
import { Locales } from 'src/lib/constants';
import { PLAID_ENV } from 'src/env';

// data
import { noJSBannerData } from 'src/store/banners';

const ForeignContentNotification = dynamic(
  () => {
    return import(
      'src/components/Feedback/ForeignContentNotification/ForeignContentNotification.tsx'
    );
  },
  {
    ssr: false,
  },
);

// For CMS pages
const TemplateWrapper = ({
  children,
  className = '',
  locale = '',
  ...props
}) => {
  const { asPath } = useRouter();

  return (
    <AnalyticsProvider>
      <LocaleContext.Provider value={locale || Locales.EN_US}>
        <div className={className}>
          <MetaTags {...props} />
          <div className='banner-container'>
            <noscript>
              <Banner {...noJSBannerData} />
            </noscript>
          </div>
          {locale === Locales.EN_GB || locale === Locales.EN_EU ? (
            <MainNav {...props} />
          ) : (
            <MainMenu />
          )}
          <ForeignContentNotification />
          <div
            id='main-content'
            className={`${getCurrentPage(asPath) || 'homepage'} default`}
            data-env={PLAID_ENV}
          >
            {children}
          </div>
          <Unless condition={props && props['exclude-footer']}>
            <Footer {...props} />
          </Unless>
          <ConsentManager />
          <UnidentifiedTraffic />
        </div>
      </LocaleContext.Provider>
    </AnalyticsProvider>
  );
};

export default TemplateWrapper;
