import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';

import { MetaTags } from 'src/components/MetaTags';
import { Unless } from 'src/components/helpers';
import { ConsentManager } from 'src/containers/ConsentManager';
import { MainNav } from 'src/components/navigation';
import Footer from 'src/components/footer';
import { MainMenu } from 'src/sections/Navigation';

// helpers
import { LocaleContext } from 'src/contexts';
import { Locales } from 'src/lib/constants';

// dynamically import the scripts component which invokes all of our js dependencies
const DocsScripts = dynamic(() => import('./DocsScripts'), {
  ssr: false,
});

// For legal, developer policy, terms-of-use, DPR forms
const DocsTemplate = ({ children, locale = Locales.EN_US, ...props }) => {
  const { route } = useRouter();

  return (
    <LocaleContext.Provider value={locale}>
      <MetaTags {...props} />
      {locale === Locales.EN_GB || locale === Locales.EN_EU ? (
        <MainNav skipLinkAnchor='#docsArticle' />
      ) : (
        <MainMenu skipLinkAnchor='#docsArticle' />
      )}
      {children}
      <Unless condition={props && props['exclude-footer']}>
        <Footer />
      </Unless>
      <ConsentManager />
      <DocsScripts route={route} />
    </LocaleContext.Provider>
  );
};
export default DocsTemplate;
