import React from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { MainNav } from 'src/components/navigation';
import { ConsentManager } from 'src/containers/ConsentManager';
import { Banner, UnidentifiedTraffic } from 'src/components/Feedback';
import { MetaTags } from 'src/components/MetaTags';
import { Link } from 'src/components/Inputs';
import { noJSBannerData } from 'src/store/banners';
import { AnalyticsProvider, LocaleContext } from 'src/contexts';
import { Locales } from 'src/lib/constants';
import { MainMenu } from 'src/sections/Navigation';

// dynamically import the scripts component which invokes all of our js dependencies
const DemoTemplateScripts = dynamic(
  () => {
    return import('src/templates/DemoTemplate/DemoTemplateScripts');
  },
  {
    ssr: false,
  },
);

const Demo = ({ children, locale = Locales.EN_US, ...props }) => {
  const { route } = useRouter();

  return (
    <LocaleContext.Provider value={locale}>
      <AnalyticsProvider>
        <MetaTags {...props}>
          <script src='https://cdn.plaid.com/link/v2/stable/link-initialize.js' />
        </MetaTags>
        <noscript>
          <Banner {...noJSBannerData} />
        </noscript>

        {locale === Locales.EN_GB || locale === Locales.EN_EU ? (
          <MainNav skipLinkAnchor='#demo-content' />
        ) : (
          <MainMenu skipLinkAnchor='#demo-content' />
        )}
        <div id='demo-content' className={`accounts demo`}>
          {children}
          <div id='footer' className='footer footer--is-slim'>
            <div className='grid-container'>
              <div className='grid-x grid-margin-x footer-details footer-details--is-slim'>
                <div className='cell cell--is-line-break medium-12'></div>
                <div className='cell small-12 medium-10 menu'>
                  <p>
                    Want to learn more about Plaid?{' '}
                    <Link href='/'>
                      <a>Visit us here.</a>
                    </Link>
                  </p>
                </div>
                <div className='cell cell--align-right small-12 medium-2'>
                  <p className='footer-copyright'>
                    &copy; {new Date().getFullYear()} Plaid Inc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConsentManager />
        <UnidentifiedTraffic />
        <DemoTemplateScripts route={route} />
      </AnalyticsProvider>
    </LocaleContext.Provider>
  );
};

export default Demo;
